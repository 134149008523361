import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
    '& label.MuiInputLabel-formControl, label.Mui-focused': {
        'color': 'white'
    },
    '& div.MuiInputBase-formControl, div.MuiInputBase-formControl:hover': {
        '& fieldset, fieldset:hover': {
            'borderColor': 'white',
            'borderRadius': 0,
        },
        '& input': {
            'color': 'white'
        }
    }
});

function CustomTextField(props) {
    return <StyledTextField { ...props } />;
}

export default CustomTextField;