import { PayPalButtons } from '@paypal/react-paypal-js';
import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';

function PaypalCheckoutButton({ handleApprove, handleError, triggerValidation }) {
    const { payment_amount, form_data, is_form_valid } = useContext(AppContext);

    const description_field_length_limit = 127;
    const custom_id_field_length_limit = 256;

    const description_field = () => {
        let fullText = `Indie Giving Event - ${form_data.first_name} ${form_data.last_name} - T-Shirt: ${form_data.tshirt_size.toUpperCase()}`;
        return fullText.substring(0, description_field_length_limit);
    }

    const custom_id_field = () => {
        let fullText = `Email - ${form_data.email}`;
        if(form_data.food_restrictions) {
            fullText += ` - Food Restriction - ${form_data.food_restrictions}`;
        }
        return fullText.substring(0, custom_id_field_length_limit);
    }

    const purchase_unit = () => ({
        'description': description_field(),
        'custom_id': custom_id_field(),
        'amount': {
            'value': payment_amount
        },
    });

    const createOrder = ((data, actions) => {
        return actions.order.create({
            purchase_units: [purchase_unit()],
        });
    });
    
    return <PayPalButtons key={ is_form_valid ? JSON.stringify(form_data) : 1 } style={{
            color: 'gold',
            layout: 'horizontal',
            height: 48,
            shape: 'rect'
        }}
        onClick={ (data, actions) => {
            triggerValidation();
            if (is_form_valid) {
                return actions.resolve();
            }
            return actions.reject();
        } }
        createOrder={ createOrder }
        onApprove={ async (data, actions) => {
            const order = await actions.order.capture();
            console.log(order);
            handleApprove();
        } }
        onError={ (err) => {
            handleError(err);
        } }
    />;
}

export default PaypalCheckoutButton;