import { Box, Grid } from "@mui/material";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function CustomSlider() {
    const slider_settings = {
        'arrows': true,
        'dots': false,
        'autoplay': true,
        'autoplaySpeed': 2500,
        'centerMode': true,
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'variableWidth': true,
    };
    const images = [
        'charity_event/3.jpg',
        'charity_event/10.jpg',
        'charity_event/2.jpg',
        'charity_event/11.jpg',
        'charity_event/8.jpg',
        'charity_event/12.jpg',
        'charity_event/6.jpg',
        'charity_event/13.jpg',
        'charity_event/1.jpg',
        'charity_event/9.jpg',
    ];
    return <Grid item 
        xs={ 12 } 
        px={{
            'xs': '20px',
            'lg': 0,
        }}
        sx={{
            'marginBottom': '30px',
        }}
    >
        <Slider 
            { ...slider_settings }
        >
            { images.map(src => <Box 
                    component='img'
                    src={ `/assets/images/${src}` }
                    width='auto'
                    height='284px'
                    key={ src }
                />
            )}
        </Slider>
    </Grid>
}

export default CustomSlider;