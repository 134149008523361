import { Fragment } from 'react';
import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

const footer_links = [
    {
        'display': 'MobileFuse.com',
        'href': 'https://mobilefuse.com',
    },
    {
        'display': 'Indie Giving',
        'href': 'http://indiegiving.com',
    },
    {
        'display': 'Contact',
        'href': 'mailto:chrish@mobilefuse.com',
    },
];

const footer_links_display = footer_links.map((link, i) => {
    const separator = i + 1 === footer_links.length ? null : (<span style={{
        'paddingLeft': '5px',
        'paddingRight': '8px',
    }}>|</span>);
    
    return (<Fragment key={ link.display }>
        <a href={ link.href } 
            style={{
                'textDecoration': 'none',
                'color': '#409CF1',
            }}
        >{ link.display }</a>
        { separator }
    </Fragment>);
});

function Footer() {

    return <Box width='100%' pb={ 2 } pt={ 2 } mx='auto' sx={{
        'backgroundColor': '#EDEDED',
    }}>
        <Grid container 
            justifyContent='space-between' 
            rowGap={ 1 } 
            maxWidth='1000px' 
            ml='auto' 
            mr='auto'
            maxHeight='50px'
        >
            <Grid item 
                xs={ 12 } 
                md={ 6 } 
                display='flex' 
                justifyContent={{
                    'xs': 'center',
                    'md': 'right',
                }}
            >
                <Typography component='div'
                    sx={{
                        'color': '#545454',
                        'fontSize': '16px',
                        'fontWeight': '400',
                        'lineHeight': '1em',
                        'display': 'inline',
                        'padding': {
                            'xs': '0 5px 0 8px',
                            'md': '0 15px 0 8px'
                        },
                    }}
                >
                    Copyright 2024 Indie Giving.
                </Typography>
            </Grid>

            <Grid item 
                xs={ 12 }
                md={ 6 }
                sx={{
                    'textAlign': {
                        'xs': 'center',
                        'md': 'left',
                    },
                }}
            >
                { footer_links_display }
            </Grid>
        </Grid>
    </Box>;
}

export default Footer;
