import { Container, Grid, Typography } from '@mui/material';
import LogoRow from './LogoRow';
import { Fragment } from 'react';

function RefundPolicy() {
    return <Fragment>
        <Container 
            sx={{
                'paddingTop': '70px',
            }}
        >
            <Grid container>
                <LogoRow/>
                <Grid item
                    xs={ 12 }
                    sx={{
                        'backgroundColor': '#e2f3f9',
                        'padding': '40px',
                        'fontSize': '14px',
                        'marginTop': '50px',
                        'marginBottom': '80px',
                    }}
                >
                    <Typography variant='h2'>
                        Refund Policy
                    </Typography>
                    <Typography variant='body2'>
                        <strong>Do you already have a GDC pass?</strong>
                        <br/>
                        <br/>
                        Requests to cancel a previously purchased GDC pass must be received by the GDC (<a href='mailto:gdcregistration@informa.com'>gdcregistration@informa.com</a>) on or before February 23, 2024. Cancellations will incur a $300.00 cancellation fee for conference passes or a $100.00 cancellation fee for expo passes. Refunds are not available after this date and are not available for no-shows. Substitution requests will not incur a processing fee.
                    </Typography>
                    <Typography variant='body2'>
                        <strong>Do you want a refund for Indie Giving?</strong>
                        <br/>
                        <br/>
                        Refunds are not guaranteed.  However, you can contact us to request a refund if you no longer need your GDC ticket and cannot make the Indie Giving event. There is also the option to donate your spot to someone else.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </Fragment>;
}

export default RefundPolicy;