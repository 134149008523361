import { Grid, Box } from '@mui/material';

function SponsorsLogo({ file_name, ...grid_props }) {
    return (
        <Grid item 
        { ...grid_props }
            textAlign='center'
        >
            <Box 
                component='img'
                src={ `/assets/images/sponsors/${file_name}` } 
                sx={{
                    'maxWidth': '100%', 
                    'height': 'auto'
                }}
            />
        </Grid>
    );
}

export default SponsorsLogo;