import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<PayPalScriptProvider
			options={{
				'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
				'currency': 'USD',
			}}
		>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PayPalScriptProvider>
	</React.StrictMode>
);