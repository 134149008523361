import { Container, Grid } from '@mui/material';
import LeftDetailsBox from './LeftDetailsBox';
import RightDetailsBox from './RightDetailsBox';
import AudioBars from './AudioBars';
import LogoRow from './LogoRow';

function CharityPage() {
    return <Container 
        sx={{
            'paddingTop': '70px',
        }}
    >
        <Grid container>
            <LogoRow/>
            <LeftDetailsBox/>
            <RightDetailsBox/>
            <AudioBars/>
        </Grid>
    </Container>;
}

export default CharityPage;