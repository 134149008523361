import { Grid, Box } from '@mui/material';

function AudioBars() {
    return <Grid item xs={ 12 }>
        <Box
            component='img'
            sx={{
                'width': {
                    'xs': 'calc(100% - 40px)',
                    'lg': '100%',
                },
                'marginTop': {
                    'xs': '50px',
                    'sm': '90px',
                    'md': '145px',
                },
                'display': 'block',
            }}
            px={{
                'xs': '20px',
                'lg': 0,
            }}
            mx='auto'
            src='/assets/images/audio-bars.png'
        />
    </Grid>;
}

export default AudioBars;