import { useLocation } from 'react-router-dom';

// This hook returns true if the current route matches the given path
function useIsCurrentRoute(path) {
	const location = useLocation();
	return location.pathname === path;
}

export function useIsThanksRoute() {
	return useIsCurrentRoute('/thanks');
}

export function useIsCharityRoute() {
	return useIsCurrentRoute('/charity');
}