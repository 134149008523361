import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { AppContext } from '../contexts/AppContext';
import CustomTextField from './CustomTextField';
import PaypalCheckoutButton from './PaypalCheckoutButton';

function SignupForm() {
    const { 
        form_data,
        setFormData,
        is_form_valid,
        setIsFormValid,
        resetFormData,
        valid_shirt_sizes,
    } = useContext(AppContext);
    const [errors, setErrors] = useState({});
    const [validate_on_change, setValidateOnChange] = useState(false);
    const history = useHistory();
    const validate_on_change_ref = useRef(false);

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    }

    function isValidEmail(email) {
        const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return pattern.test(email);
    }

    function getFormValidity(data) {
        return {
            first_name: data.first_name.length >= 2,
            last_name: data.last_name.length >= 2,
            email: isValidEmail(data.email),
            tshirt_size: valid_shirt_sizes.includes(data.tshirt_size.toUpperCase()),
            food_restrictions: true,
        };
    }

    function validateForm(button_trigger) {
        const validation_results = getFormValidity(form_data);
        setErrors(validation_results);

        // Making sure all properties' values on validation_results are true
        let validity = Object.values(validation_results).every(v => v);

        // conditional to avoid highlighting errors before having tried to submit
        if(!button_trigger && validity === false && is_form_valid === null){
            validity = null;
        }
        setIsFormValid(validity);

        return validity;
    }

    useEffect(() => {
        validateForm(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_data]);

    useEffect(() => {
        // Using a reference to avoid calling it in the first render
        if (validate_on_change_ref.current !== validate_on_change) {
            validateForm(true);
        }
        
        validate_on_change_ref.current = validate_on_change;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validate_on_change]);

    function triggerValidation() {
        setValidateOnChange(!validate_on_change);
    }

    function handlePaymentApprove() {
        resetFormData();
        history.push('./thanks');
    }

    function handlePaymentError(error) {
        console.error(error);
        alert('There was an error processing your payment. Please try again later. If this error persist, please reach out to us.');
    }

    return (
        <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } sm={ 6 }>
                <CustomTextField
                    fullWidth
                    name='first_name'
                    label='* First Name'
                    value={ form_data.first_name }
                    onChange={ handleChange }
                    error={ is_form_valid !== null && !errors.first_name }
                    helperText={ is_form_valid !== null && !errors.first_name && 'First name should have at least 2 characters.' }
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
                <CustomTextField
                    fullWidth
                    name='last_name'
                    label='* Last Name'
                    value={ form_data.last_name }
                    onChange={ handleChange }
                    error={ is_form_valid !== null && !errors.last_name }
                    helperText={ is_form_valid !== null && !errors.last_name && 'Last name should have at least 2 characters.' }
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
                <CustomTextField
                    fullWidth
                    name='email'
                    label='* Email'
                    type='email'
                    value={ form_data.email }
                    onChange={ handleChange }
                    error={ is_form_valid !== null && !errors.email }
                    helperText={ is_form_valid !== null && !errors.email && 'Must be a valid email.' }
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
                <CustomTextField
                    fullWidth
                    name='tshirt_size'
                    label='* T-Shirt Size'
                    value={ form_data.tshirt_size }
                    onChange={ handleChange }
                    error={ is_form_valid !== null && !errors.tshirt_size }
                    helperText={ is_form_valid !== null && !errors.tshirt_size && 'Must be a valid tshirt size: ' + valid_shirt_sizes.join(', ') }
                    inputProps={{ 'maxLength': 3 }}
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
                <CustomTextField
                    fullWidth
                    name='food_restrictions'
                    label='Food Restrictions'
                    value={ form_data.food_restrictions }
                    onChange={ handleChange }
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
                <PaypalCheckoutButton 
                    handleApprove={ handlePaymentApprove }
                    handleError={ handlePaymentError }
                    triggerValidation={ triggerValidation }
                    is_form_valid={ is_form_valid }
                />
            </Grid>
        </Grid>
    );
}

export default SignupForm;
