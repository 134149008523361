import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function LogoRow() {
    return <Grid item 
        xs={ 12 } 
    >
        <Link to='/'>
            <Box 
                component='img'
                src='/assets/images/indiegiving-logo.png'
                width='auto'
                height='65px'
            />
        </Link>
    </Grid>;
}

export default LogoRow;