import { Grid, Typography } from '@mui/material';
import InfoBoxesContainer from './InfoBoxesContainer';
import { useIsCharityRoute } from '../routeUtils';
import { Fragment } from 'react';

function LeftDetailsBox() {
    const is_charity_route = useIsCharityRoute();
    
    const header_text = is_charity_route ? 'CHARITY EVENT ONLY' : 'Get GDC 2024 tickets + support a local charity';

    const charity_instructions = (<Fragment>If you don't need a GDC ticket, but would 
    like to take part in the charity event, sign up here. The charity event will be supporting 
    &nbsp;<a style={{'color': '#fff', 'textDecoration':'underline'}} href='https://www.compass-sf.org/'>Compass Family Services</a>.</Fragment>);
    
    const general_instructions = (<Fragment>The indie giving event is a special program designed to help game developers attend 
    the 2024 Game Developer Conference in San Francisco as well as contribute to local 
    charitable organizations. Everyone who signs up will get the chance to take part in 
    a charity event to support <a style={{'color': '#fff'}} href='https://www.compass-sf.org/'>Compass Family Services</a>.</Fragment>);

    return (
        <Grid item 
            xs={ 12 }
            md={ 6 }
            style={{
                    'color': '#FFFFFF',
            }}
            paddingRight={{
                'md': '15px'
            }}
            marginTop='70px'
        >
            <Typography 
                component='div'
                variant='h1'
                style={{
                    'fontSize': '40px',
                    'fontWeight': '300',
                    'lineHeight': '54px',
                    'textTransform': 'uppercase',
                    'marginBottom': '30px',
                }}
            >
                { header_text }
            </Typography>
            <Typography 
                component='div'
                variant='body1'
                marginBottom={{
                    'xs': '42px',
                    'lg': '22px',
                }}
            >
                { is_charity_route ? charity_instructions : general_instructions }
                <br />
                Disclaimer: Attendance at the Indie Giving charity event is required to receive GDC pass
            </Typography>
            <InfoBoxesContainer/>
        </Grid>
    );
}

export default LeftDetailsBox;

