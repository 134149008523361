import { Grid, Typography } from '@mui/material';

function ThanksBox() {
    return <Grid item
        xs={ 12 }
        sx={{
            'backgroundColor': '#e2f3f9',
            'padding': '40px',
            'fontSize': '14px',
            'margin': '-25px 0 40px 0',
        }}
    >
        <Typography variant='h2'>
            We have successfully received your payment.
        </Typography>
        <Typography variant='body2'>
            Thank you for signing up for Indie Giving 2024, brought to you by MobileFuse and GDC! This message is to confirm your registration for the Indie Giving package and event.
        </Typography>    
        <Typography variant='body2'>
            <strong>GDC Ticket/Badge Info</strong>
            <br/>
            By participating in Indie Giving, you will receive an All Access Pass for GDC 2024. To learn more about what this particular pass grants you access to, please <a href='https://www.gdconf.com/passes-prices' target='_blank' rel='noreferrer'>click here</a>.
        </Typography>
        <Typography variant='body2'>
            We will send more information about how to collect your badge in a future email.
        </Typography>
        <Typography variant='body2'>
            <strong>Charity Event</strong>
            <br/>
            We will have more details once we get closer to the date, and you will be updated via email.
        </Typography>
        <Typography variant='body2'>
            If you have any questions, please email chrish@mobilefuse.com
        </Typography>
    </Grid>;
}

export default ThanksBox;