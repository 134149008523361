import {
    Grid,
    Box,
    Button,
    Container,
    Typography,
    Hidden
} from '@mui/material';
import SponsorsLogo from './SponsorsLogo';

function Sponsors() {
    return (
        <Box 
            width='100%'
            pb='150px'
            pt='170px'
            style={{
                'backgroundColor': 'white'
            }}
        >
            <Container>
                <Grid container>
                    <Grid item xs={ 12 } md={ 8 }>
                        <Box 
                            sx={{
                                'display': 'flex',
                                'alignItems': 'center',
                                'justifyContent': {
                                    'xs': 'center',
                                    'md': 'flex-start',
                                },
                                'flexDirection': 'row',
                            }}
                        >
                            <Box
                                component='img'
                                src='/assets/images/sponsors-icon.png'
                                sx={{
                                    'width': 'auto',
                                    'height': '65px',
                                    'float': 'left',
                                    'paddingRight': '20px',
                                }}
                            />
                            <Typography variant='h1' sx={{
                                'color': '#545454',
                                'fontSize': '38px',
                                'fontWeight': '300',
                                'lineHeight': '65px',
                                'marginLeft': '20px',
                                'display': 'inline',
                            }}>
                                Sponsors
                            </Typography>
                            <Hidden smDown>
                                <Typography variant='h3' sx={{
                                    'color': '#C9C9C9',
                                    'fontSize': '25px',
                                    'fontWeight': '300',
                                    'lineHeight': '32px',
                                    'marginLeft': '10px',
                                    'marginTop': '10px',
                                    'display': 'inline',
                                }}>
                                    /show some love
                                </Typography>
                            </Hidden>
                        </Box>
                    </Grid>
                    <Hidden smUp>
                        <Grid item xs={12} md={8}>
                            <Typography variant='h3' sx={{
                                'color': '#C9C9C9',
                                'fontSize': '25px',
                                'fontWeight': '300',
                                'lineHeight': '32px',
                                'marginLeft': '10px',
                                'marginTop': '10px',
                                'marginBottom': '10px',
                                'textAlign': 'center',
                            }}>
                                /show some love
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Grid item
                        xs={ 12 }
                        md={ 4 }
                        textAlign={{
                            'xs': 'center',
                            'md': 'right',
                        }}
                    >
                        <Button variant='outlined' 
                            href='mailto:chrish@mobilefuse.com?Subject=Indie%20Giving%20Sponsor%20Inquiry' 
                            sx={{
                                'color': '#FFFFFF',
                                'backgroundColor': '#409CF1',
                                'width': '250px',
                                'height': '50px',
                                'fontSize': '19px',
                                'fontWeight': '700',
                                'lineHeight': '40px',
                                'display': 'inline-block',
                                ':hover': {
                                    'backgroundColor': '#409CF1',
                                    'color': '#FFFFFF'
                                },
                                'textAlign': 'center',
                            }}
                        >
                            Become a sponsor
                        </Button>
                    </Grid>
                    <Grid item
                        xs={ 12 }
                        pt='110px'
                        pb='70px'
                    >
                        <Typography>
                            This community effort is organized by MobileFuse, with the support from our 
                            sponsors. We use the money from sponsors to pay for tools, food and anything needed for 
                            the volunteers. Every penny leftover is donated. Together, we&apos;ve not only saved these 
                            organizations hundreds of work hours, but we&apos;ve also donated tens of thousands of dollars 
                            on the behalf of our contributors over the years. If you can&apos;t make it to the charity 
                            event, please consider becoming a sponsor to help out financially.
                        </Typography>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <Grid container 
                            spacing={ 4 } 
                            sx={{
                                'display': 'flex',
                                'alignItems':'center',
                            }}
                        >
                            <SponsorsLogo file_name='gdc-logo.png' xs={ 12 } sm={ 6 }/>
                            <SponsorsLogo file_name='mf-logo.png' xs={ 12 } sm={ 6 }/>
                            <SponsorsLogo file_name='ptb-logo-solid-blue.svg' xs={ 12 } sm={ 6 }/>
                            <SponsorsLogo file_name='flowplay-logo.png' xs={ 12 } sm={ 6 } md={ 3 }/>
                            <SponsorsLogo file_name='fgl-logo.png' xs={ 12 } md={ 3 }/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Sponsors;