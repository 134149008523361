import React, { Fragment, useContext } from 'react';
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { useIsCharityRoute } from '../routeUtils';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from '../contexts/AppContext';
import SignupForm from './SignupForm';

function RightDetailsBox() {
    const is_charity_page = useIsCharityRoute();
    const { is_campaign_active, payment_amount } = useContext(AppContext);

    const list_items = is_charity_page ? (<li className='indie-giving-list-content'>Charity event participation only</li>) : (
        <Fragment>
            <li className='indie-giving-list-content'>1 GDC All Access pass</li>
            <li className='indie-giving-list-content'>Charity event participation</li>
            <li className='indie-giving-list-content'>Awesome t-shirts</li>
        </Fragment>
    );
    const gdc_text = is_charity_page ? 'If you also want a GDC pass, click here' : 'Click here if you want to attend, but do not need a GDC ticket';
    const gdc_link = is_charity_page ? '/' : '/charity';

    return <Grid item 
        xs={ 12 } 
        md={ 6 }
        marginTop={{
            'xs': '30px',
            'md': 0,
        }}
        paddingLeft={{
            'md': '15px'
        }}
    >
        <Box
            component='div'
            sx={{
                'boxSizing': 'border-box',
                'position': 'relative',
            }}
        >
            <Box sx={{
                'backgroundColor': '#CEF3FF',
                'width': '100%',
                'height': 'auto',
                'position': 'relative',
                'paddingBottom': '20px',
                'color': '#525D60',
            }}>
                <Box>
                    <Typography variant='h1'
                        sx={{
                            'fontSize': '19px',
                            'fontWeight': '300',
                            'lineHeight': '30px',
                            'paddingLeft': '30px',
                            'top': {
                                'xs': '100px',
                                'sm': '30px',
                            },
                            'position': 'relative',
                            'display': 'inline',
                        }}
                    >
                        { is_campaign_active ? 'Indie Giving Details' : 'Indie Giving 2024 Sold Out!' }
                    </Typography>
                    { is_campaign_active && <Box sx={{
                        'backgroundColor': '#DD56AF',
                        'width': '100px',
                        'height': '44px',
                        'borderRadius': '6px',
                        'color': '#FFFFFF',
                        'fontSize': '22px',
                        'fontWeight': '400',
                        'lineHeight': '44px',
                        'right': '0',
                        'textAlign': 'center',
                        'marginTop': {
                            'xs': '0',
                            'sm': '15px',
                        },
                        'marginBottom': '0',
                        'marginRight': {
                            'xs': 'auto',
                            'sm': '30px',
                        },
                        'marginLeft': {
                            'xs': 'auto',
                            'sm': '0',
                        },
                        'display': {
                            'xs': 'block',
                            'sm': 'inline',
                        },
                        'position': {
                            'xs': 'relative',
                            'sm': 'absolute',
                        },
                    }}>
                        ${ payment_amount }
                    </Box> }
                </Box>
                <div style={{
                    'backgroundColor': '#A9CFDB',
                    'height': '1px',
                    'display': 'block',
                    'position': 'relative',
                    'margin': '70px 30px 25px 30px',
                }}/>
                <ul className='indie-giving-list' style={{
                    'listStyleType': 'none',
                }}>
                    { list_items }
                </ul>
            </Box>
            <Box sx={{
                'backgroundColor': '#237A96',
                'paddingX': '20px',
                'paddingBottom': '10px',
                'position': 'relative',
            }}>
                <Typography variant='h1'
                    sx={{
                        'color': '#FFFFFF',
                        'fontSize': '18px',
                        'fontWeight': '400',
                        'lineHeight': '24px',
                        'paddingTop': '40px',
                        'paddingBottom': '25px',
                        'margin': '0',
                    }}
                >
                    { is_campaign_active ? 'Signup for Indie Giving' : 'If you would like to participate next year, please drop us a line.' }
                </Typography>
                { is_campaign_active ? <SignupForm/> : null }
            </Box>
        </Box>
        <Typography variant='body2' paddingTop='5px'>
            <Link to={ gdc_link } style={{
                'color': '#ffffff',
                'fontWeight': 'bold',
                'textDecoration': 'unset',
            }}>» { gdc_text }</Link>
        </Typography>
        
        <Typography variant='body2' paddingTop='5px'>
            <Link to='/refund-policy' style={{
                'color': '#ffffff',
                'fontWeight': 'bold',
                'textDecoration': 'unset',
            }}>» Important information regarding GDC or Indie Giving cancellations</Link>
        </Typography>
    </Grid>
}

export default RightDetailsBox;