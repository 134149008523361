import { Box } from '@mui/material';

function InfoBox({ children }) {
    return (
        <Box
            component='div'
            sx={{
                'backgroundImage': 'url(assets/images/location-background.png)',
                'backgroundSize': '190px 96px',
                'width': '190px',
                'height': '96px',
                'display': 'inline-block',
                'color': '#FFFFFF',
                'fontSize': '18px',
                'fontWeight': '400',
                'lineHeight': '16px',
                'textAlign': 'left',
                'padding': '20px 0 0 30px',
                'paddingLeft': '15px',
                'boxSizing': 'border-box',
                'marginRight': '20px',
                '@media (max-width:600px)': {
                    'marginBottom': '20px',
                    'marginRight': '0',
                },
            }}
        >
            { children }
        </Box>
    );
}

export default InfoBox;