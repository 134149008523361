import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import '../App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './Footer';
import LandingPage from './LandingPage';
import CharityPage from './CharityPage';

import {
    Box,
} from '@mui/material';
import { AppContext } from '../contexts/AppContext';
import { useIsCharityRoute } from '../routeUtils';
import RefundPolicy from './RefundPolicy';

function App() {
    const is_charity_page = useIsCharityRoute();
    const theme = createTheme({
        'components': {
            'MuiTypography': {
                'styleOverrides': {
                    'body2': {
                        'marginBlockStart': '1em',
                        'marginBlocEend': '1em',
                        'fontFamily': `'Lato', sans-serif`,
                    },
                    'h1': {
                        'fontFamily': `'Lato', sans-serif`,
                    },
                    'h2': {
                        'fontFamily': `'Lato', sans-serif`,
                        'fontSize': '32px',
                        'fontWeight': 400,
                        'margin': 0,
                    },
                },
            },
        },
        'palette': {
            'white': {
                'main': 'white'
            }
        }
    });

    function getInitialFormData() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            tshirt_size: '',
            food_restrictions: ''
        };
    }

    const [is_form_valid, setIsFormValid] = useState(null);
    const [form_data, setFormData] = useState({ ...getInitialFormData() });

    const config = {
        is_campaign_active: false,
        payment_amount_charity: process.env.REACT_APP_DEV === 'true' ? 0.01 : 30,
        payment_amount_standard: process.env.REACT_APP_DEV === 'true' ? 0.02 : 499,
        valid_shirt_sizes: ['S', 'M', 'L', 'XL', 'XXL'],
    };

    const payment_amount = is_charity_page ? config.payment_amount_charity : config.payment_amount_standard;

    function resetFormData() {
        setFormData({ ...getInitialFormData() });
        setIsFormValid(null);
    }

    return <AppContext.Provider value={{
        form_data,
        is_campaign_active: config.is_campaign_active,
        is_form_valid,
        payment_amount,
        resetFormData,
        setFormData,
        setIsFormValid,
        valid_shirt_sizes: config.valid_shirt_sizes,
    }}>
        <ThemeProvider theme={ theme }>
            <Box
                style={{
                    'paddingLeft': 0,
                    'paddingRight': 0,
                    'maxWidth': 'unset',
                    'backgroundColor': '#33B4DE',
                }}
            >
                <Switch>
                    <Route path='/refund-policy'>
                        <RefundPolicy/>
                    </Route>

                    <Route path='/charity'>
                        <CharityPage/>
                    </Route>

                    <Route path='/thanks'>
                        <LandingPage/>
                    </Route>

                    <Route path='/'>
                        <LandingPage/>
                    </Route>
                </Switch>
                <Footer/>
            </Box>
        </ThemeProvider>
    </AppContext.Provider>;
}

export default App;
